import cx from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { urls } from 'services/_url';
import { Button } from 'app/shared/button';
import { Alert } from 'app/shared/form-alert';
import { IconInput, SimpleInput } from 'app/shared/input';
import styles from '../guest.module.css';
import { ReactComponent as Icon } from 'assets/icons/auth/SignupIcon.svg';
import { isEmailValid } from 'app/shared/utils/input';
import { generateUniqueId } from 'app/shared/utils/general';
import { useGuestService } from 'hooks/guest';
import { Spacer } from 'app/layouts/generic';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { useQuery } from 'app/hooks/query';

const SignupForm = () => {
  const history = useNavigate();
  const location = useLocation();
  const query = useQuery();
  const { resendActivationLink, signup } = useGuestService();

  const [alert, setAlert] = useState({});
  const [account_type, setAccountType] = useState('regular');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [email_to_resend, setEmailToResend] = useState('');
  const [loading, setLoading] = useState(false);
  const [membership_data, setMembershipData] = useState({});
  const [password, setPassword] = useState('');
  const [resending, setResending] = useState(false);
  const [show_password, setShowPassword] = useState(false);
  const [show_resend_button, setShowResendButton] = useState(false);
  const [is_membership_acceptance, setIsMembershipAcceptance] = useState(false);
  const [errors, setErrors] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: ''
  });

  const clearForm = () => {
    setFirstname('');
    setLastname('');
    setEmail('');
    setPassword('');
  };

  useEffect(() => {
    const token = window.localStorage.getItem('acceptance');
    if (!token) return;

    const [user_email, tenant_id] = token.split(':');
    setIsMembershipAcceptance(!!token);
    setMembershipData({ user_email, tenant_id });
    handleEmailChange(user_email);
  }, []);

  useEffect(() => {
    const query_params = new URLSearchParams(location.search);
    const email_from_query = query_params.get('email');
    if (email_from_query) {
      setEmail(email_from_query);
    }
  }, [location.search]);

  const validateField = useCallback(
    (fieldName, value) => {
      let errorMessage = '';
      switch (fieldName) {
        case 'firstname':
          if (!value.trim()) {
            errorMessage = 'First name is required.';
          }
          break;
        case 'lastname':
          if (!value.trim()) {
            errorMessage = 'Last name is required.';
          }
          break;
        case 'email':
          if (!value.trim()) {
            errorMessage = 'Email address is required.';
          } else if (!isEmailValid(value)) {
            errorMessage = 'Invalid email address.';
          }
          break;
        case 'password':
          if (!value.trim()) {
            errorMessage = 'Password is required.';
          } else if (value.length < 8) {
            errorMessage = 'Password must be at least eight characters long.';
          }
          break;
        default:
          break;
      }
      setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: errorMessage }));
    },
    [errors]
  );

  const handleFirstnameChange = (value) => {
    setFirstname(value);
    validateField('firstname', value);
  };

  const handleLastnameChange = (value) => {
    setLastname(value);
    validateField('lastname', value);
  };

  const handleEmailChange = (value) => {
    setEmail(value);
    validateField('email', value);
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
    validateField('password', value);
  };

  const submitForm = () => {
    if (disableSubmitButton) {
      validateField('firstname', firstname);
      validateField('lastname', lastname);
      validateField('email', email);
      validateField('password', password);
      return;
    }

    const utm = window.localStorage.getItem('utm');
    const form_data = {
      account_type,
      firstname,
      lastname,
      email,
      password,
      membership_data,
      utm_data: JSON.parse(utm) || {}
    };

    const data_to_submit = { ...form_data };

    setLoading(true);
    signup({ data: data_to_submit })
      .then((response) => {
        const { error, payload } = response;
        if (error) {
          setAlert({
            message: error,
            id: generateUniqueId()
          });
          return;
        }

        if (query.ref) {
          window.localStorage.setItem('ref', query.ref);
        }

        setEmailToResend(email);
        setShowResendButton(true);
        setAlert({
          message: payload,
          type: 'success',
          id: generateUniqueId()
        });
        clearForm();
      })
      .finally(() => {
        setLoading(false);
        window.localStorage.removeItem('utm');
        window.localStorage.removeItem('acceptance');
      });
  };

  const toggleShowPassword = () => {
    setShowPassword(!show_password);
  };

  const handleResend = () => {
    setResending(true);
    resendActivationLink(email_to_resend)
      .then((response) => {
        const { error, payload } = response;
        if (error) {
          setAlert({
            message: error,
            id: generateUniqueId()
          });
          return;
        }
        setAlert({
          message: payload,
          type: 'success',
          id: generateUniqueId()
        });
      })
      .finally(() => setResending(false));
  };

  const isFormDisabled = (errors, values) => {
    const hasErrors = Object.values(errors).some((error) => error !== '');
    const isFieldsIncomplete = Object.values(values).some((value) => !value.trim());
    return hasErrors || isFieldsIncomplete;
  };

  const disableSubmitButton = isFormDisabled(errors, {
    firstname,
    lastname,
    email,
    password
  });

  return (
    <div className={styles.authWrapper}>
      <div className={styles.iconContainer}>
        <Icon />
      </div>
      <div>
        <h1>
          Get started with{' '}
          <span className={styles.siteName} aria-labelledby="Site Name">
            Go-Mailer<span className={styles.stub}>.</span>
          </span>
        </h1>
        <p>No subscription costs. Unlimited access.</p>
        <form>
          <Alert {...alert} />
          {show_resend_button && (
            <div className={styles.formText}>
              {!resending ? <b onClick={handleResend}>Resend Link</b> : <b>Resending</b>}
            </div>
          )}
          <SimpleInput
            value={firstname}
            onInput={handleFirstnameChange}
            placeholder="First name"
            className={styles.formInput}
            wrapperClassName={styles.inputMargin}
            error={errors.firstname}
          />
          <SimpleInput
            value={lastname}
            onInput={handleLastnameChange}
            placeholder="Last name"
            className={styles.formInput}
            wrapperClassName={styles.inputMargin}
            error={errors.lastname}
          />
          <SimpleInput
            type="email"
            value={email}
            onInput={handleEmailChange}
            placeholder="Email address"
            className={styles.formInput}
            wrapperClassName={styles.inputMargin}
            error={errors.email}
            disabled={is_membership_acceptance}
          />
          <IconInput
            icon_name={show_password ? 'hide' : 'show'}
            type={show_password ? 'text' : 'password'}
            placeholder="Password"
            value={password}
            onInput={handlePasswordChange}
            onIconClick={toggleShowPassword}
            icon_position="right"
            wrapperClassName={styles.formInput}
            error={errors.password}
          />
          <Spacer multiple={3} />
          <GridRow num_of_columns={2}>
            <GridColumn>
              <div
                className={cx(
                  styles.accountOptionWrapper,
                  account_type === 'regular' && styles.isActive
                )}
                onClick={() => setAccountType('regular')}
              >
                Regular Account
              </div>
            </GridColumn>
            <GridColumn>
              <div
                className={cx(
                  styles.accountOptionWrapper,
                  account_type === 'affiliate' && styles.isActive
                )}
                onClick={() => setAccountType('affiliate')}
              >
                Affiliate Account
              </div>
            </GridColumn>
          </GridRow>
          <Spacer multiple={3} />
          <div className={styles.noticeText}>
            <div>
              By continuing, you agree to our{' '}
              <b>
                <a href={urls.terms}>Terms of Service</a>
              </b>{' '}
              and{' '}
              <b>
                <a href={urls.policy}>Privacy Policy</a>
              </b>
              .
            </div>
          </div>
          <Button
            className={styles.formBtn}
            onClick={submitForm}
            text="Sign up"
            loading={loading}
          />
          <div className={styles.cta}>
            <span>Already have an account?</span>
            <button className={styles.actionBtn} onClick={() => history('/login')}>
              Log in
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignupForm;
