import { Spacer } from 'app/layouts/generic';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { Button } from 'app/shared/button';
import { TimeSeriesChart } from 'app/shared/charts/timeseries';
import ContextMenu from 'app/shared/datatable/context-menu/context-menu';
import { DateRangePicker } from 'app/shared/datepickers';
import GmModal from 'app/shared/modal/modal';
import SlidingNav from 'app/shared/nav/sliding/sliding';
import { generateDefaultRange } from 'app/shared/utils/date';
import { useAutomationConfigService } from 'hooks/automation/config';
import { useEventsService } from 'hooks/automation/event';
import { useEventLogService } from 'hooks/automation/eventlog';
import { useEventReportService } from 'hooks/automation/reports/event';
import { useContactService } from 'hooks/users/contact';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setPageTitle } from 'store/actions/header';
import { DistributionChartInstance } from './distribution';
import { ChartCreationForm } from './form/chart';
import { DistributionTableCreationForm } from './form/table';
import { EventHistory } from './history';
import { DistributionTable } from './series';
import { EventStatBar } from './stat';
import styles from './view.module.css';

export const EventViewPage = () => {
  const { event_code } = useParams();
  const dispatch = useDispatch();
  const { tenant_id } = useSelector((store) => store.user_data);
  const code = `${event_code}:${tenant_id}`;

  const { fetchAutomationConfigs, updateAutomationConfig } = useAutomationConfigService();
  const { fetchContactsBulk } = useContactService();
  const { fetchEvents } = useEventsService();
  const { fetchEventLogs } = useEventLogService();
  const { fetchEventReports } = useEventReportService();

  const [charts, setCharts] = useState([]);
  const [chart_date_ranges, setChartDateRanges] = useState([]);
  const [chart_filter_selections, setChartFilterSelections] = useState([]);
  const [chart_filters, setChartFilters] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [is_loading_performance_data, setIsLoadingPerformanceData] = useState(true);
  const [is_loading_distribution_data, setIsLoadingDistributionData] = useState(true);
  const [logs, setLogs] = useState([]);
  const [performance_data, setPerformanceData] = useState({});
  const [show_chart_creation_form, setShowChartCreationForm] = useState(false);
  const [show_table_creation_form, setShowTableCreationForm] = useState(false);
  const [tables, setTables] = useState([]);

  const fetchEventLogsInPeriod = async ({ code = '', start, end, eventlogs = [], page = 0 }) => {
    const { logs } = await fetchEventLogs({
      query_string: `time_stamp=${start}~${end}&code=${code}&return_only=contact_email&page=${page}&population=2000`
    });

    if (!logs.length) return eventlogs;
    return await fetchEventLogsInPeriod({
      code,
      start,
      end,
      eventlogs: [...eventlogs, ...logs],
      page: page + 1
    });
  };

  const fetchLogContacts = async ({ emails = [] }) => {
    let contacts = [];
    const batch_size = Math.ceil(emails.length / 2000);

    for (let i = 0; i < batch_size; i++) {
      const { contacts: payload } = await fetchContactsBulk({ data: { email: emails.join(',') } });
      contacts = [...contacts, ...payload];
      if (!payload.length) break;
    }

    return contacts;
  };

  const handleCreationAction = (data) => {
    if (typeof data !== 'string') return;

    switch (data.toLowerCase()) {
      case 'create_chart':
        setShowChartCreationForm(true);
        break;
      case 'create_table':
        setShowTableCreationForm(true);
        break;
      default:
    }
  };

  const handleDateSelection = async (value) => {
    const [start, end] = value;
    setIsLoadingDistributionData(true);
    const eventlogs = await fetchEventLogsInPeriod({
      code,
      start: Date.parse(start),
      end: Date.parse(end)
    });

    setLogs(eventlogs);
    const emails = (eventlogs || []).map((log) => log.contact_email);
    const contacts = await fetchLogContacts({ emails });

    setContacts(contacts);
    setLogs(eventlogs);
    setIsLoadingDistributionData(false);
  };

  const handleChartCreation = (data = []) => {
    setCharts(data);
  };

  const handleChartDeletion = async (field) => {
    const allowed_charts = charts.filter((chart) => chart !== field);
    const result = await updateAutomationConfig({
      data: { options: {}, data: { charts: allowed_charts } }
    });
    if (result) setCharts(allowed_charts);
  };

  const handleTableCreation = (data = []) => {
    setTables(data);
  };

  const handleTableDeletion = async (field) => {
    const allowed_tables = tables.filter((table) => table !== field);
    const result = await updateAutomationConfig({
      data: { options: {}, data: { tables: allowed_tables } }
    });
    if (result) setTables(allowed_tables);
  };

  const prepareInvocationTimeSeries = async (ranges = [], selections = []) => {
    if (!ranges.length) return;

    const day_map = ranges.reduce(
      (sac, { label }) => ({
        ...sac,
        [label]: { day: label }
      }),
      {}
    );

    const start = ranges[0].start;
    const end = ranges[ranges.length - 1].end;
    const secondary_event = selections.find((selection) => selection.value !== event_code);
    const secondary_event_code = secondary_event ? secondary_event.code : 'unknown';
    setIsLoadingPerformanceData(true);

    const [{ reports: main_reports }, { reports: secondary_reports }] = await Promise.all([
      fetchEventReports({
        query_string: `time_stamp=${start}~${end}&code=${code}`
      }),
      fetchEventReports({
        query_string: `time_stamp=${start}~${end}&code=${secondary_event_code}`
      })
    ]);

    const [parsed_main_code] = code?.split(':');
    const [parsed_secondary_code] = secondary_event_code?.split(':');

    setPerformanceData(day_map);
    setIsLoadingPerformanceData(false);

    const parsed_data = {};
    for (const range of ranges) {
      parsed_data[range.label] = {
        day: range.label,
        [parsed_main_code]: 0,
        [parsed_secondary_code]: 0
      };

      for (const report of main_reports) {
        if (report.day >= range.start && report.day <= range.end) {
          parsed_data[range.label][parsed_main_code] += report.invocations.unique;
        }
      }

      for (const report of secondary_reports) {
        if (report.day >= range.start && report.day <= range.end) {
          parsed_data[range.label][parsed_secondary_code] += report.invocations.unique;
        }
      }
      setPerformanceData((curr_data) => ({ ...curr_data, ...parsed_data }));
    }
  };

  useEffect(() => {
    fetchAutomationConfigs().then(({ configs: [config] }) => {
      setCharts(config?.charts || []);
      setTables(config?.tables || []);
    });
  }, [event_code]);

  useEffect(() => {
    if (!event_code) return;
    dispatch(
      setPageTitle([
        { title: 'App Insights', path: '/insights/app' },
        { title: event_code, path: '' }
      ])
    );

    fetchEvents({ query_string: `code=!${code}&type=!system` }).then(({ events }) => {
      const primary_event = events.find((event) => event_code === event.code) || {
        code: event_code,
        name: event_code
      };

      const secondary_events = events.map((event) => ({
        code: event.code,
        color: 'var(--orange-primary)',
        label: event.name,
        value: event.code?.split(':')[0]
      }));

      setChartFilters([
        {
          color: 'var(--blue-primary)',
          default: true,
          is_fixed: true,
          label: primary_event.name,
          value: primary_event.code
        },
        ...secondary_events
      ]);
    });
    handleDateSelection(generateDefaultRange());
  }, [event_code]);

  useEffect(() => {
    prepareInvocationTimeSeries(chart_date_ranges, chart_filter_selections);
  }, [chart_filter_selections, chart_date_ranges]);

  return (
    <>
      <SlidingNav
        nav_items={[]}
        actions={
          <div className={styles.headerActions}>
            <DateRangePicker
              initial_value={generateDefaultRange()}
              onChange={handleDateSelection}
            />
            <ContextMenu
              actions={[
                { label: 'Distribution chart', value: 'create_chart' },
                { label: 'Distribution table', value: 'create_table' }
              ]}
              callback={handleCreationAction}
              position="right"
              text={<Button text="Create" icon_name="add" className={styles.headerBtn} />}
            />
          </div>
        }
      />
      <Spacer multiple={4} />
      <EventStatBar />
      <Spacer multiple={4} />
      <GridRow num_of_columns={12}>
        <GridColumn span={9}>
          {chart_filters.length > 0 && (
            <GridRow num_of_columns={1}>
              <TimeSeriesChart
                data={Object.values(performance_data)}
                graph_title={`${event_code} over time`}
                is_loading_data={is_loading_performance_data}
                key_filters={chart_filters}
                onDateRangeChange={setChartDateRanges}
                onKeyFilterChange={setChartFilterSelections}
              />
            </GridRow>
          )}
          <Spacer multiple={4} />
          <GridRow num_of_columns={1}>
            {tables.map((table) => (
              <DistributionTable
                code={event_code}
                event_code={code}
                name={table}
                key={table}
                onDelete={handleTableDeletion}
              />
            ))}
          </GridRow>
          <Spacer multiple={4} />
          <GridRow num_of_columns={2}>
            {charts.map((chart) => (
              <DistributionChartInstance
                key={chart}
                event_code={event_code}
                field={chart}
                contacts={contacts}
                logs={logs}
                is_loading={is_loading_distribution_data}
                onDelete={handleChartDeletion}
              />
            ))}
          </GridRow>
        </GridColumn>
        <GridColumn span={3}>
          <EventHistory code={code} />
        </GridColumn>
      </GridRow>
      <GmModal
        bodyClassName={styles.chartCreationModal}
        title="Create Distribution Chart"
        show_modal={show_chart_creation_form}
        show_title
        onClose={() => setShowChartCreationForm(false)}
      >
        <ChartCreationForm onSave={handleChartCreation} />
      </GmModal>
      <GmModal
        bodyClassName={styles.chartCreationModal}
        title="Create Distribution Table"
        show_modal={show_table_creation_form}
        show_title
        onClose={() => setShowTableCreationForm(false)}
      >
        <DistributionTableCreationForm onSave={handleTableCreation} />
      </GmModal>
    </>
  );
};
