import React from 'react';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import { DetailItem } from 'app/shared/info-section/item';
import { Icon } from 'assets/icons';
import useScreenSize from 'hooks/size';
import { GridColumn, GridRow } from 'app/layouts/grid';
import styles from '../view.module.css';
import { Badge } from 'app/shared/badge';
import { toDateString } from 'app/shared/utils/date';

const ListItemDisplay = ({ text }) => {
  return <span className={styles.listItem}>{text}</span>;
};

export const ContactBasicInformation = ({ contact_data, onEditClick = () => {} }) => {
  const date_of_birth = contact_data.date_of_birth
    ? new Date(contact_data.date_of_birth).toDateString()
    : '-';

  return (
    <BasicInfoSection
      title="Basic Details"
      icon={<Icon name="edit" onClick={() => onEditClick(() => 'edit')} />}
    >
      <DetailItem title="First name">{contact_data.firstname || '-'}</DetailItem>
      <DetailItem title="Last name">{contact_data.lastname || '-'}</DetailItem>
      <DetailItem title="Email address">{contact_data.email || '-'}</DetailItem>
      <DetailItem title="Phone number">{contact_data.phone_number || '-'}</DetailItem>
      <DetailItem title="Gender">{contact_data.gender || '-'}</DetailItem>
      <DetailItem title="Date of birth">{date_of_birth}</DetailItem>
      <DetailItem title="Events">
        {contact_data?.events?.length
          ? (contact_data.events || []).map((event, index) => (
              <ListItemDisplay key={index} text={event.split(':')[0]} />
            ))
          : '-'}
      </DetailItem>
      <DetailItem title="Tags">
        <div>
          {contact_data.parsed_tags?.length
            ? (contact_data.parsed_tags || []).map((tag, index) => (
                <ListItemDisplay text={tag} key={index} />
              ))
            : '-'}
        </div>
      </DetailItem>
      <DetailItem title="Street address">{contact_data?.address?.street || '-'}</DetailItem>
      <DetailItem title="Country">{contact_data?.address?.country || '-'}</DetailItem>
    </BasicInfoSection>
  );
};

export const ContactCustomInformation = ({ data, date_keys = [] }) => {
  const { isMobile } = useScreenSize();

  const displayValue = (value, key = '') => {
    if (value === undefined) return '-';
    if (Array.isArray(value)) return value.join(', ');
    if (value === true) return <Badge type="green" text="true" />;
    if (value === false) return <Badge type="red" text="false" />;
    if (!isNaN(value) && date_keys.includes(key)) {
      return toDateString(value);
    }

    return value;
  };

  return (
    <BasicInfoSection title={isMobile && 'Custom Information'}>
      <GridRow num_of_columns={isMobile ? 1 : 2}>
        {Object.keys(data).map((key) => (
          <GridColumn key={key}>
            <DetailItem title={key}>{displayValue(data[key], key)}</DetailItem>
          </GridColumn>
        ))}
      </GridRow>
      {Object.keys(data).length === 0 && <div>Contact does not have custom information</div>}
    </BasicInfoSection>
  );
};
