import { Spacer } from 'app/layouts/generic';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { Button } from 'app/shared/button';
import { MultiSelect } from 'app/shared/select';
import { generateRandomNumber } from 'app/shared/utils/number';
import { useContactFileService } from 'hooks/file-config';
import { useAudienceService } from 'hooks/users/audience';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styles from './query.module.css';
import { QueryRule } from './rule';

const OPERATORS = [
  { label: 'OR', value: '$or' },
  { label: 'AND', value: '$and' }
];

const DEFAULT_PROPERTIES = {
  firstname: { label: 'First Name', value: 'firstname', kind: 'default' },
  lastname: { label: 'Last Name', value: 'lastname', kind: 'default' },
  email: { label: 'Email Address', value: 'email', kind: 'default' },
  event: { label: 'Events Invoked', value: 'events', kind: 'default' },
  tags: { label: 'Tags', value: 'tags', kind: 'default' },
  last_action: { label: 'Last Action', value: 'last_action', kind: 'custom' },
  last_action_time: { label: 'Last Action Time', value: 'last_action_time', kind: 'custom' }
};

export const AudienceQueryBuilder = ({ onQueryChange }) => {
  const { id: audience_id } = useParams();
  const { fetchAudienceById, updateAudience } = useAudienceService();
  const { fetchMapping } = useContactFileService();

  const [operator, setOperator] = useState(OPERATORS[0]);
  const [properties, setProperties] = useState([]);
  const [queries, setQueries] = useState({});

  useEffect(() => {
    if (!audience_id) return;
    fetchAudienceById(audience_id).then(({ audience }) => {
      const { query } = audience;

      if (query) {
        setOperator(
          () => OPERATORS.find((operator) => operator.value === query.operator) || OPERATORS[0]
        );

        query.rules?.forEach((rule) => {
          const key = `query:${rule._id || generateRandomNumber()}`;
          setQueries((curr_queries) => ({ ...curr_queries, [key]: { ...rule, key } }));
        });
      }
    });

    fetchMapping().then(({ mapping }) => {
      const { custom_fields } = mapping || {};
      const parsed_fields = (custom_fields || []).reduce(
        (sac, field) => ({
          ...sac,
          [field]: { label: `${field}`, value: field, kind: 'custom' }
        }),
        {}
      );

      const allowed_properties = { ...parsed_fields, ...DEFAULT_PROPERTIES };
      setProperties(Object.values(allowed_properties));
    });
  }, [audience_id]);

  const handleRuleAddition = () => {
    const key = `query:${generateRandomNumber()}`;
    setQueries((curr_queries) => ({ ...curr_queries, [key]: { key } }));
  };

  const handleRuleDeletion = (key) => {
    const new_queries = { ...queries };
    delete new_queries[key];
    setQueries(() => new_queries);
  };

  const handleQueryChange = (query = {}) => {
    setQueries((queries) => ({
      ...queries,
      [query.key]: query
    }));
  };

  const submit = async () => {
    const valid_queries = Object.values(queries).filter((query) => !!query.needle);
    if (!valid_queries || !valid_queries.length) {
      toast.error('Please add at least one query');
      return;
    }

    const data = {
      query: { operator: operator.value, rules: valid_queries }
    };

    await updateAudience(audience_id, { data });
    onQueryChange();

    toast.success('Query saved.');
  };

  return (
    <div className={styles.wrapper}>
      <header>
        <GridRow num_of_columns={4}>
          <GridColumn>
            <MultiSelect
              options={OPERATORS}
              value={operator}
              onChange={setOperator}
              isSorted={false}
            />
          </GridColumn>
          <GridColumn span={2} />
          <GridColumn>
            <Button text="Save Query" onClick={submit} />
          </GridColumn>
        </GridRow>
      </header>
      <Spacer multiple={6} />
      <section>
        {Object.keys(queries).map((key, index) => (
          <QueryRule
            key={key}
            index={index}
            data={queries[key]}
            properties={properties}
            onChange={handleQueryChange}
            onDelete={handleRuleDeletion}
          />
        ))}
      </section>
      <GridRow num_of_columns={12}>
        <GridColumn>
          <Button icon_name="add" onClick={handleRuleAddition} />
        </GridColumn>
      </GridRow>
    </div>
  );
};
