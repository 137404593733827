/** */
import { toast } from 'react-toastify';
import React, { useState, useEffect, useContext } from 'react';
import fileSaver from 'js-file-download';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'app/shared/button';
import { MultiSelect } from 'app/shared/select';
import { useAudienceService } from 'hooks/users/audience';
import { useContactService } from 'hooks/users/contact';
import { addManyAudiencesToStore } from 'store/actions/audience';
import useScreenSize from 'hooks/size';
import { Spacer } from 'app/layouts/generic';
import { BubbleDisplayContext } from 'contexts/bubble';
import { UploadJob } from './job';
import { useTagService } from 'hooks/users/tag';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { DetailItem } from 'app/shared/info-section/item';

const ImportContact = ({ closeModal, mailing_list }) => {
  const dispatch = useDispatch();
  const { isMobile } = useScreenSize();
  const { addItem, removeItem } = useContext(BubbleDisplayContext);
  const { fetchAudiences } = useAudienceService();
  const { downloadTemplate, uploadContacts } = useContactService();
  const { fetchTags } = useTagService();

  const { id: tenant_id } = useSelector((state) => state.user_data);
  const audiences_in_store = useSelector((state) => state.audiences);

  const [file, setFile] = useState(null);
  const [mailing_lists, setMailingLists] = useState([]);
  const [selected_lists, setSelectedList] = useState([]);
  const [selected_tags, setSelectedTags] = useState([]);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    setMailingLists(() =>
      Object.values(audiences_in_store).filter((audience) => !audience.is_test_audience)
    );
  }, [audiences_in_store]);

  useEffect(() => {
    fetchAudiences({
      query_string: 'is_archived=0&return_only=name,id,is_test_audience'
    }).then(({ audiences, error }) => {
      if (error) {
        toast.error('Unable to fetch audience at this time.');
        return;
      }
      dispatch(addManyAudiencesToStore(audiences));
    });

    fetchTags().then(({ tags }) => {
      if (!tags) return;
      const tag_map = tags.reduce(
        (sac, tag) => ({ ...sac, [tag.code]: { ...tag, value: tag.code, label: tag.value } }),
        {}
      );
      setTags(Object.values(tag_map));
    });
  }, []);

  const downloadContactsTemplate = async () => {
    const response = await downloadTemplate();
    fileSaver(response, 'contacts.csv');
  };

  const resetImport = () => {
    setSelectedList([]);
    setFile(null);
  };

  const supported_extensions = ['.csv', '.xlsx'];
  const submit = async () => {
    if (!file) {
      toast.error('no file selected');
      return;
    }

    if (!supported_extensions.some((ext) => file.name.endsWith(ext))) {
      toast.error('Only csv or xlsx format is supported');
      return;
    }

    const audiences = selected_lists.map((list) => list.value);
    const tags = selected_tags.map((tag) => tag.code);
    const job = {
      id: file.name,
      file,
      uploadFunction: uploadContacts,
      context: { tenant_id, audiences, tags }
    };

    addItem(
      job.id,
      <UploadJob
        key={job.id}
        job={job}
        onComplete={() => {
          removeItem(job.id);
        }}
      />
    );

    closeModal();
    resetImport();
  };

  return (
    <div>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="Select file (csv)" required>
            <div className="custom-file">
              <input
                type="file"
                accept=".csv,.xlsx"
                className="custom-file-input"
                id="contact_file"
                onChange={(e) => setFile(e.target.files[0])}
                onClick={(e) => (e.target.value = null)}
              />
              <label className="custom-file-label" htmlFor="contact_file">
                {file ? file.name : 'Select file'}
              </label>
            </div>
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={2}>
        <GridColumn>
          <DetailItem title="Select audience">
            {mailing_list ? (
              <select className="custom-select" disabled>
                <option>{mailing_list.name}</option>
              </select>
            ) : (
              <MultiSelect
                options={mailing_lists.map((list) => ({
                  label: list.name,
                  value: list.id,
                  is_test_audience: list.is_test_audience
                }))}
                onChange={setSelectedList}
                value={selected_lists}
                placeholder="Select audience(s)"
                isMulti
              />
            )}
          </DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem title="select tags">
            <MultiSelect options={tags} value={selected_tags} onChange={setSelectedTags} isMulti />
          </DetailItem>
        </GridColumn>
      </GridRow>
      {isMobile && <Spacer multiple={2} />}
      <GridRow num_of_columns={5}>
        <GridColumn />
        <GridColumn span={2}>
          <Button
            onClick={() => downloadContactsTemplate()}
            className="w-100"
            text="Download Template"
            type="secondary"
          />
        </GridColumn>
        <GridColumn span={2}>
          <Button onClick={submit} className="w-100" text="Import" />
        </GridColumn>
      </GridRow>
    </div>
  );
};

export default ImportContact;
