import { Spacer } from 'app/layouts/generic';
import { ProgressBar } from 'app/shared/progress/bar';
import { Icon } from 'assets/icons';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

const JOB_STATES = {
  paused: 'paused',
  in_progress: 'uploading',
  done: 'complete'
};

export const UploadJob = ({ job, onComplete = () => {} }) => {
  const { context, file, uploadFunction } = job;
  const CHUNK_SIZE = 5 * 1024 * 1024;

  const [headers, setHeaders] = useState(null);
  const [job_state, setJobState] = useState(JOB_STATES.in_progress);
  const [last_position, setLastPosition] = useState(0);
  const [progress, setProgress] = useState(0);
  const [show_job, setShowJob] = useState(true);

  const styles = {
    wrapper: {
      backgroundColor: 'var(--neutral-light)',
      border: '1px solid var(--neutral-dark-5)',
      padding: '20px'
    },
    title: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px'
    },
    [JOB_STATES.done]: {
      backgroundColor: 'var(--green-primary)'
    },
    [JOB_STATES.paused]: {
      backgroundColor: 'var(--danger)'
    }
  };

  useEffect(() => {
    if (job_state === JOB_STATES.done) {
      setTimeout(() => {
        toast.info('File uploaded. You will be notified when import is complete.');
        onComplete();
        setShowJob(false);
      }, 1000);
    }

    if (job_state !== JOB_STATES.in_progress) return;
    execute();
  }, [job_state]);

  const execute = async () => {
    let file_headers = headers;
    let start = last_position;
    let status = job_state;
    let chunkToUpload = file.slice(start, start + CHUNK_SIZE);

    while (chunkToUpload && !!chunkToUpload.size && status !== JOB_STATES.paused) {
      const data = new FormData();
      data.append('file', chunkToUpload);
      data.append('audience_ids', context.audiences || []);
      data.append('tags', context.tags || []);
      data.append('tenant_id', context.tenant_id);
      data.append('type', file.type);

      if (chunkToUpload.size < CHUNK_SIZE) {
        data.append('is_last_chunk', true);
      }

      if (file_headers) {
        data.append('entity_id', file_headers.entity_id);
      }

      setProgress(() => Math.round((start / file.size) * 100));
      const result = await uploadFunction({ data });
      if (!result) {
        status = JOB_STATES.paused;
        setLastPosition(() => start);
        chunkToUpload = [];
        break;
      }

      if (result.entity_id) {
        setHeaders(() => result);
        file_headers = result;
      }

      setLastPosition(() => start);
      start = start + CHUNK_SIZE;
      chunkToUpload = file.slice(start, start + CHUNK_SIZE);
    }

    if (status !== JOB_STATES.paused) {
      setJobState(() => JOB_STATES.done);
      setProgress(() => 100);
      setLastPosition(() => 0);
    } else setJobState(() => JOB_STATES.paused);
  };

  const handleRemoval = () => {
    onComplete();
    setShowJob(() => false);
  };

  const handleResume = () => {
    setJobState(() => JOB_STATES.in_progress);
  };

  return (
    <>
      {show_job ? (
        <div style={styles.wrapper}>
          <div style={styles.title}>
            <span>{file.name}</span>
            {job_state === JOB_STATES.paused && <Icon name="close" onClick={handleRemoval} />}
          </div>
          <Spacer multiple={2} />
          <div style={styles.controls}>
            <ProgressBar value={progress} bar_style={styles[job_state]} />
            {job_state === JOB_STATES.paused && <Icon name="refresh" onClick={handleResume} />}
          </div>
        </div>
      ) : null}
    </>
  );
};
