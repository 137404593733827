import { GridColumn, GridRow } from 'app/layouts/grid';
import { DetailItem } from 'app/shared/info-section/item';
import { SimpleInput } from 'app/shared/input';
import { MultiSelect } from 'app/shared/select';
import { Icon } from 'assets/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { DateInput } from '../inputs/date';
import { EventInput } from '../inputs/event';
import styles from '../query.module.css';
import { determineRules } from './helper';

const DATA_TYPES = [
  { label: 'Text', value: 'string' },
  { label: 'Boolean', value: 'boolean' },
  { label: 'Date', value: 'date' },
  { label: 'Number', value: 'number' },
  { label: 'Array', value: 'array' },
  { label: 'Event', value: 'event' }
];

export const QueryRule = ({
  data = {},
  index = 0,
  properties = [],
  onChange = () => {},
  onDelete = () => {}
}) => {
  const [key, setKey] = useState(data.key);
  const [needle, setNeedle] = useState('');
  const [rules, setRules] = useState([]);
  const [selected_datatype, setSelectedDatatype] = useState({});
  const [selected_property, setSelectedProperty] = useState({});
  const [selected_rule, setSelectedRule] = useState({});

  useEffect(() => {
    setKey(() => data.key);
  }, []);

  useEffect(() => {
    const possible_rules = determineRules(selected_datatype?.value);
    const default_rule = selected_property?.value === 'last_action' ? {} : possible_rules[0];

    setRules(() => possible_rules);
    setSelectedRule(() => default_rule);
    setNeedle(() => '');
  }, [selected_property, selected_datatype]);

  useEffect(() => {
    if (selected_datatype?.value !== 'boolean' || !selected_rule?.value) return;
    setNeedle(selected_rule.value);
  }, [selected_datatype, selected_rule]);

  useEffect(() => {
    handleQueryChange(selected_property, selected_rule, needle);
  }, [index, selected_rule, needle]);

  useEffect(() => {
    if (selected_property?.value === 'last_action') {
      setSelectedDatatype({});
      setSelectedRule({});
      return;
    }

    if (selected_property?.value === 'last_action_time') {
      setSelectedDatatype(() => DATA_TYPES.find((type) => type.value === 'date'));
      return;
    }

    setSelectedDatatype(DATA_TYPES[0]);
    if (selected_property?.value === 'events') {
      return setSelectedDatatype({ label: 'Event', value: 'event' });
    }
  }, [selected_property]);

  useMemo(() => {
    if (!data || !Object.keys(data).length) return;
    const possible_rules = determineRules(data.datatype);

    if (data.property !== 'last_action') {
      setSelectedDatatype(() =>
        DATA_TYPES.find((type) => type.value === data.datatype || type.value === 'string')
      );
      setSelectedRule(() => possible_rules.find((rule) => rule.value === data.rule));
    }
    setSelectedProperty(() => properties.find((prop) => prop.value === data.property));
    setNeedle(() => data.needle);
  }, [properties, data.property, data.datatype, data.rule, data.needle]);

  const handleQueryChange = (selected_property, selected_rule, needle) => {
    if (!selected_property || !selected_rule || !needle) return;
    const query = {
      key,
      datatype: selected_datatype?.value,
      property: selected_property?.value,
      rule: selected_rule?.value,
      kind: selected_property?.kind,
      needle: selected_property?.value === 'last_action' ? needle.split(':')[0] : needle
    };

    onChange(query);
  };

  const is_needle_disabled = (datatype) => {
    return datatype === 'boolean';
  };

  return (
    <GridRow num_of_columns={5}>
      <GridColumn>
        <DetailItem title="property">
          <MultiSelect
            options={properties}
            value={selected_property}
            onChange={setSelectedProperty}
          />
        </DetailItem>
      </GridColumn>
      <GridColumn>
        <DetailItem title="data type">
          <MultiSelect
            options={DATA_TYPES}
            value={selected_datatype}
            onChange={setSelectedDatatype}
            disabled={
              selected_datatype?.value === 'event' ||
              ['last_action', 'last_action_time'].includes(selected_property?.value)
            }
          />
        </DetailItem>
      </GridColumn>
      <GridColumn>
        <DetailItem title="rule">
          <MultiSelect
            options={rules}
            value={selected_rule}
            onChange={setSelectedRule}
            isSorted={false}
            disabled={selected_property?.value === 'last_action'}
          />
        </DetailItem>
      </GridColumn>
      <GridColumn span={2}>
        <GridRow num_of_columns={10}>
          <GridColumn span={9}>
            {selected_datatype?.value !== 'date' ? (
              selected_datatype?.value !== 'event' && selected_property?.value !== 'last_action' ? (
                <DetailItem title="value">
                  <SimpleInput
                    type={selected_datatype?.value === 'number' ? 'number' : 'text'}
                    disabled={is_needle_disabled(selected_datatype?.value)}
                    value={needle}
                    onInput={setNeedle}
                  />
                </DetailItem>
              ) : (
                <EventInput value={needle} onChange={setNeedle} />
              )
            ) : (
              <DateInput rule={selected_rule?.value} value={needle} onChange={setNeedle} />
            )}
          </GridColumn>
          <GridColumn>
            <div className={styles.icon}>
              <Icon name="trash" onClick={() => onDelete(key)} />
            </div>
          </GridColumn>
        </GridRow>
      </GridColumn>
    </GridRow>
  );
};
