import { GridColumn, GridRow } from 'app/layouts/grid';
import { Button } from 'app/shared/button';
import { DetailItem } from 'app/shared/info-section/item';
import { MultiSelect } from 'app/shared/select';
import { TreeContext } from 'contexts/automation';
import { useResourceQuery } from 'hooks/automation/resources';
import { useAutomationStepService } from 'hooks/automation/steps';
import React, { useContext, useEffect, useState } from 'react';
import styles from './config.module.css';
import { useEffectsService } from 'hooks/automation/effects';
import { toast } from 'react-toastify';
import { SimpleInput } from 'app/shared/input';

export const AutomationActionConfig = () => {
  const { step_modal, toggleStepModal } = useContext(TreeContext);

  const { fetchEffects } = useEffectsService();
  const { fetchResources } = useResourceQuery();
  const { createStep, updateStep } = useAutomationStepService();

  const [attr_key, setAttrKey] = useState('');
  const [attr_value, setAttrValue] = useState('');
  const [effects, setEffects] = useState([]);
  const [creating, setCreating] = useState(false);
  const [resources, setResources] = useState([]);
  const [selected_action, setSelectedAction] = useState(null);
  const [selected_resources, setSelectedResources] = useState(null);

  //
  const is_edit_mode = step_modal.mode === 'edit';

  useEffect(() => {
    fetchEffects().then(({ effects: raw_effects, error }) => {
      if (error) return toast.error(error);

      const parsed_effects = raw_effects.map((effect) => ({
        ...effect,
        label: effect.name,
        value: effect.code
      }));

      setEffects(() => parsed_effects);
    });
  }, []);

  useEffect(() => {
    if (!effects.length || !is_edit_mode) return;
    const { data } = step_modal;
    const { effect: chosen_effect } = data;

    if (!chosen_effect) return;
    setSelectedAction(() => effects.find((effect) => effect.code === chosen_effect) || {});
  }, [step_modal.mode, effects]);

  useEffect(() => {
    setSelectedResources([]);
    if (!selected_action || !Object.keys(selected_action).length) return;

    if (selected_action.resource_class === 'attribute') {
      const { data } = step_modal;
      if (data && data.target) {
        const [, config] = data.target.split(':');
        const attr = config.split(',');
        setAttrKey(attr[0]);
        setAttrValue(attr[1]);
      }

      return;
    }

    fetchResources(selected_action.resource_class, '').then(
      ({ error, resources: raw_resources }) => {
        if (error) return;

        const res_map = {};
        const parsed_resources = raw_resources.map((res) => {
          const parsed_resource = {
            label:
              selected_action.resource_class === 'tag'
                ? res.value
                : res.name || res.title || res.link,
            value: selected_action.resource_class === 'tag' ? res.code : res.id
          };

          res_map[parsed_resource.value] = parsed_resource;

          return {
            ...res,
            ...parsed_resource
          };
        });

        setResources(() => parsed_resources);

        if (is_edit_mode && selected_action.value === step_modal.data.effect) {
          const { data } = step_modal;
          const [, ...resource_ids] = data.target.split(':');
          if (!resource_ids || resource_ids.length < 1) return;
          const res_ids =
            selected_action.resource_class === 'tag' ? resource_ids[0].split(',') : resource_ids;
          setSelectedResources(() => res_ids.map((res_id) => res_map[res_id]));
        }
      }
    );
  }, [selected_action]);

  const handleActionSelection = (selection = null) => {
    if (!selection) return;
    setSelectedAction(selection);
  };

  const handleResourceSelection = (selections = []) => {
    if (!selections) return;
    setSelectedResources(selections);
  };

  const submit = async () => {
    const resources = selected_resources.map((res) => res?.value);
    let separator = ':';
    if (selected_action.resource_class === 'tag') {
      separator = ',';
    }

    let target = `${selected_action.resource_class}:${resources.join(separator)}`;
    if (selected_action.resource_class === 'attribute') {
      target = `${selected_action.resource_class}:${attr_key},${attr_value}`;
    }

    const data = {
      branch_value: is_edit_mode ? step_modal.data.branch_value : step_modal.branch_value,
      children: [],
      effect: selected_action.value,
      parents: step_modal.parents,
      source: is_edit_mode ? step_modal.data.source : step_modal.source,
      target,
      template_id: step_modal.template_id,
      type: 'action',
      rules: is_edit_mode ? step_modal.data.rules : step_modal.rules
    };

    try {
      const step_id = is_edit_mode ? step_modal.data.id : null;
      setCreating(() => true);
      const result = step_id ? await updateStep(step_id, data) : await createStep(data);
      if (result) {
        setSelectedResources([]);
        setSelectedAction({});
        toggleStepModal({ show: false });
      }
    } finally {
      setCreating(() => false);
    }
  };

  return (
    <div className={styles.formWrapper}>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="Action">
            <MultiSelect
              options={effects}
              onChange={handleActionSelection}
              value={selected_action}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={1}>
        <GridColumn>
          {selected_action?.resource_class === 'attribute' ? (
            <>
              <DetailItem title="Attribute name" required>
                <SimpleInput value={attr_key} onInput={setAttrKey} />
              </DetailItem>
              <DetailItem title="Attribute value" required>
                <SimpleInput value={attr_value} onInput={setAttrValue} />
              </DetailItem>
            </>
          ) : (
            <DetailItem title={`Select ${selected_action?.resource_class}`}>
              <MultiSelect
                options={resources}
                onChange={handleResourceSelection}
                value={selected_resources}
                isMulti
              />
            </DetailItem>
          )}
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={3}>
        <GridColumn />
        <GridColumn />
        <GridColumn>
          <Button text="Save" disabled={creating} onClick={submit} />
        </GridColumn>
      </GridRow>
    </div>
  );
};
