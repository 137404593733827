import { truncateText } from 'app/shared/utils/general';
import styles from './list.module.css';
import cx from 'classnames';

export const table_config = (permissions) => {
  return {
    actions: {
      bulk: [
        {
          value: 'add_to_audience',
          label: 'Add to audience',
          hidden: !permissions['contact:update']
        },
        { value: 'delete', label: 'Delete', hidden: !permissions['contact:delete'] },
        { value: 'assign_tag', label: 'Assign Tags', hidden: !permissions['contact:update'] }
      ],
      single: (contact) => [
        {
          value: 'add_to_audience',
          label: 'Add to audience',
          hidden: !permissions['contact:update']
        },
        {
          value: 'mark_as_valid',
          label: 'Mark as valid',
          hidden: !!contact.is_valid || !permissions['contact:update']
        },
        { value: 'delete', label: 'Delete', hidden: !permissions['contact:delete'] },
        { value: 'manage_tag', label: 'Manage Tags', hidden: !permissions['contact:update'] }
      ]
    },
    allow_bulk_action: true,
    css: {},
    fields: [
      {
        title: 'First name',
        key: 'firstname',
        isTitle: true
      },
      {
        title: 'Last name',
        key: 'lastname',
        isTitle: true
      },
      {
        title: 'Email',
        key: 'email',
        isTagline: true,
        formatter: (value) => {
          return truncateText(value, 35) || 'V/A';
        }
      },
      {
        title: 'Status',
        key: 'is_valid',
        formatter: (value) => {
          let badge_class = styles.green;
          switch (value) {
            case true:
              badge_class = styles.green;
              value = 'valid';
              break;
            case false:
              badge_class = styles.red;
              value = 'invalid';
              break;
          }
          return <span className={cx(styles.badge, badge_class)}>{value}</span>;
        }
      },
      {
        title: 'Date added',
        key: 'created_on',
        formatter: (value) => {
          return value ? new Date(value).toDateString() : '';
        },
        isMetadata: true
      }
    ],
    items: [],
    // search_key: 'name',
    search_text: ''
  };
};
