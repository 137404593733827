import { useSelector } from 'react-redux';
import * as AudienceService from 'services/audience';
import * as CampaignService from 'services/campaign';
import * as LinksService from 'services/link';
import * as SMSService from 'services/messaging/sms';
import * as TagService from 'services/users/tag';
import * as TransactionalService from 'services/transactional';
import * as WebPushService from 'services/messaging/push/web';
import {
  EFFECT_SEND_TRANSACTIONAL,
  EFFECT_SUBSCRIBE,
  EFFECT_UNSUBSCRIBE,
  EVENT_BIRTHDAY,
  EVENT_CLICKED_LINK,
  EVENT_NEW_CONTACT,
  EVENT_OPENED_CAMPAIGN,
  EVENT_OPENED_TRANSACTIONAL,
  EVENT_SUBSCRIPTION,
  EVENT_UNSUBSCRIPTION
} from 'app/shared/constants/automation';

export const determineEvents = (events = [], parent_data = null) => {
  const root_events = [
    EVENT_BIRTHDAY,
    EVENT_CLICKED_LINK,
    EVENT_OPENED_CAMPAIGN,
    EVENT_OPENED_TRANSACTIONAL,
    EVENT_NEW_CONTACT,
    EVENT_SUBSCRIPTION,
    EVENT_UNSUBSCRIPTION
  ];
  const child_events = [EVENT_OPENED_TRANSACTIONAL, EVENT_SUBSCRIPTION, EVENT_UNSUBSCRIPTION];
  return events.filter((event) => {
    if (event.type === 'user') return true;
    if (!parent_data) {
      return !!root_events.includes(event.code);
    }

    if (
      parent_data.effect !== EFFECT_SEND_TRANSACTIONAL &&
      (event.code === EVENT_CLICKED_LINK || event.code === EVENT_OPENED_TRANSACTIONAL)
    ) {
      return false;
    }

    if (
      (parent_data.effect !== EFFECT_SUBSCRIBE && event.code === EVENT_SUBSCRIPTION) ||
      (parent_data.effect !== EFFECT_UNSUBSCRIBE && event.code === EVENT_UNSUBSCRIPTION)
    ) {
      return false;
    }
    return !!child_events.includes(event.code);
  });
};

export const useResourceQuery = () => {
  const { token } = useSelector((store) => store.user_data);
  const fetchResources = async (type, keyword, options = {}) => {
    let response = { error: 'Invalid resource class', payload: {} };
    switch (type) {
      case 'transactional':
        response = await TransactionalService.search('name', keyword, { ...options, token });
        break;
      case 'campaign':
        response = await CampaignService.search('name', keyword, { ...options, token });
        break;
      case 'audience':
        response = await AudienceService.search('name', keyword, {
          ...options,
          query_string: `${options.query_string}&bool=-is_archived`,
          token
        });
        break;
      case 'link':
        response = await LinksService.read({ ...options, token });
        break;
      case 'sms':
        response = await SMSService.read({ ...options, token });
        break;
      case 'tag':
        response = await TagService.read({ ...options, token });
        break;
      case 'web_push':
        response = await WebPushService.read({ ...options, token });
        break;
      default:
    }

    const { error, payload } = response;
    if (error) {
      return { error: 'Unable to load resources at this time', resources: [] };
    }

    const { data: resources } = payload;
    return { resources, error: null };
  };

  return { fetchResources };
};
