import {
  EFFECT_ADD_CONTACT_ATTRIBUTE,
  EFFECT_ADD_CONTACT_TAG,
  EFFECT_REMOVE_CONTACT_TAG,
  EFFECT_SEND_SMS,
  EFFECT_SEND_TRANSACTIONAL,
  EFFECT_SEND_WEB_PUSH,
  EFFECT_SUBSCRIBE,
  EFFECT_UNSUBSCRIBE
} from 'app/shared/constants/automation';

export const formatResourceTitle = (data = {}) => {
  const [, ...resources] = data.target.split(':');
  switch (data.effect) {
    case EFFECT_ADD_CONTACT_ATTRIBUTE:
      return (
        <span>
          Add{' '}
          <b>
            <em>{resources[0].split(',')[0]}</em>
          </b>{' '}
          attribute to contact
        </span>
      );
    case EFFECT_ADD_CONTACT_TAG:
      return (
        <span>
          Add{' '}
          <b>
            <em>{resources[0].split(',').join(', ')}</em>
          </b>{' '}
          tag to contact
        </span>
      );
    case EFFECT_REMOVE_CONTACT_TAG:
      return (
        <span>
          Remove{' '}
          <b>
            <em>{resources[0].split(',').join(', ')}</em>
          </b>{' '}
          tag from contact
        </span>
      );
    case EFFECT_SEND_SMS:
      return (
        <span>
          Send <b>{resources.length}</b> message{resources.length > 1 ? 's' : ''} to the contact
        </span>
      );
    case EFFECT_SEND_TRANSACTIONAL:
      return (
        <span>
          Send <b>{resources.length}</b> transactional{resources.length > 1 ? 's' : ''} to the
          contact
        </span>
      );
    case EFFECT_SEND_WEB_PUSH:
      return (
        <span>
          Send <b>{resources.length}</b> web push message{resources.length > 1 ? 's' : ''} to the
          contact
        </span>
      );
    case EFFECT_SUBSCRIBE:
      return (
        <span>
          Add contact to <b>{resources.length}</b> audience{resources.length > 1 ? 's' : ''}
        </span>
      );
    case EFFECT_UNSUBSCRIBE:
      return (
        <span>
          Remove contact from <b>{resources.length}</b> audience{resources.length > 1 ? 's' : ''}
        </span>
      );
  }

  return 'Unknown action';
};
