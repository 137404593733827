import { toast } from 'react-toastify';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { ContactBasicInformation, ContactCustomInformation } from '../basic-info';
import { ContactUpdateForm } from '../edit-info';
import { Spacer } from 'app/layouts/generic';
import { isEmailValid } from 'app/shared/utils/input';
import { useState } from 'react';
import { useContactService } from 'hooks/users/contact';
import { useDispatch } from 'react-redux';
import { addOneContactToStore } from 'store/actions/contact';
import { useParams } from 'react-router-dom';
import useScreenSize from 'hooks/size';
import { flattenObject, scrambleSensitiveData } from 'app/shared/utils/text';

const ContactDetails = ({ contact_data, setContactData }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { isMobile } = useScreenSize();
  const { updateContact } = useContactService();

  const [mode, setMode] = useState('view');
  const [is_saving, setSaving] = useState(false);

  const extra_data = () => {
    const SENSITIVE_KEYS = ['password', 'pin', 'code'];
    const flattened_data = flattenObject(contact_data.extra_data || {});
    return scrambleSensitiveData(SENSITIVE_KEYS, flattened_data);
  };

  const handleSave = async (data) => {
    const data_to_save = {
      ...data
    };

    if (!data_to_save.email || !isEmailValid(data_to_save.email)) {
      return toast.error('Email address is required.');
    }

    delete data_to_save.id;
    setSaving(() => false);
    const payload = await updateContact(id, { data: data_to_save });
    if (payload) {
      toast.success('Contact updated successfully.');
      dispatch(addOneContactToStore({ ...data_to_save, ...payload }));
      setContactData(() => ({ ...data_to_save, ...payload }));
      setMode(() => 'view');
    }
    setSaving(() => false);
  };

  return (
    <>
      <GridRow>
        <GridColumn span={4}>
          {mode === 'view' ? (
            <ContactBasicInformation contact_data={contact_data} onEditClick={setMode} />
          ) : (
            <ContactUpdateForm
              contact_data={contact_data}
              is_saving={is_saving}
              onCancel={() => setMode('view')}
              onSave={handleSave}
            />
          )}
        </GridColumn>
      </GridRow>

      <Spacer multiple={4} />
      {isMobile && !!Object.keys(extra_data()).length && (
        <GridRow>
          <GridColumn span={4}>
            <ContactCustomInformation data={extra_data()} />
          </GridColumn>
        </GridRow>
      )}
    </>
  );
};

export default ContactDetails;
