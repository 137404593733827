import { DatePicker } from '@arco-design/web-react';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { DetailItem } from 'app/shared/info-section/item';
import { SimpleInput } from 'app/shared/input';
import { MultiSelect } from 'app/shared/select';
import React, { useEffect, useMemo, useState } from 'react';
import styles from '../query.module.css';

const CYCLES = [
  { label: 'Month(s)', value: 'month' },
  { label: 'Year(s)', value: 'year' }
];

export const DateInput = ({ value, rule, onChange }) => {
  const [show_picker, setShowPicker] = useState(true);
  const [selected_date, setSelectedDate] = useState('');
  const [duration, setDuration] = useState(1);
  const [cycle, setCycle] = useState(CYCLES[0] || {});

  useEffect(() => {
    if (!value) return;
    if (value.includes(':')) {
      const [dur, cyc] = value.split(':');
      setDuration(dur);
      setCycle(CYCLES.find((cycle) => cycle.value === cyc));
    } else {
      setSelectedDate(value);
    }
  }, [value]);

  useEffect(() => {
    console.log({ value, rule, onChange });
    setShowPicker(!['$min', '$max'].includes(rule));
  }, [rule]);

  useMemo(() => {
    if (!duration || !cycle) return;
    onChange(`${duration}:${cycle.value}`);
  }, [duration, cycle.value]);

  useMemo(() => {
    onChange(selected_date);
  }, [selected_date]);

  return (
    <>
      {show_picker ? (
        <DetailItem title="Select date">
          <DatePicker
            className={styles.datepicker}
            onChange={setSelectedDate}
            value={selected_date}
          />
        </DetailItem>
      ) : (
        <GridRow num_of_columns={3}>
          <GridColumn>
            <DetailItem title="duration">
              <SimpleInput type="number" min={1} value={duration} onInput={setDuration} />
            </DetailItem>
          </GridColumn>
          <GridColumn span={2}>
            <DetailItem title="cycle">
              <MultiSelect options={CYCLES} onChange={setCycle} value={cycle} />
            </DetailItem>
          </GridColumn>
        </GridRow>
      )}
    </>
  );
};
