import { GridColumn, GridRow } from 'app/layouts/grid';
import { Button } from 'app/shared/button';
import { DetailItem } from 'app/shared/info-section/item';
import { SimpleInput } from 'app/shared/input';
import { useTagService } from 'hooks/users/tag';
import useValidator from 'hooks/validator';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { tagValidator } from 'validators/tag';

export const TagConfigForm = ({ tag_data = {}, closeModal = () => {}, onSave = () => {} }) => {
  const { createTag, updateTag } = useTagService();
  const { form_is_valid, errors, validateField } = useValidator(tagValidator);

  const [code, setCode] = useState('');
  const [description, setDescription] = useState('');
  const [is_saving, setIsSaving] = useState(false);
  const [value, setValue] = useState('');

  useMemo(() => {
    setCode(tag_data.code);
    setDescription(tag_data.description);
    setValue(tag_data.value);
  }, [tag_data]);

  useEffect(() => {
    validateField('code', code);
    validateField('value', value);
  }, [code, value]);

  const clearForm = () => {
    setCode('');
    setValue('');
    setDescription('');
  };

  const submit = async () => {
    setIsSaving(true);
    try {
      const data = { code, value, description };
      const result = tag_data.id
        ? await updateTag(tag_data.id, { data })
        : await createTag({ data });

      if (result) {
        onSave({ ...tag_data, ...data, ...result });
        clearForm();
        closeModal();
        toast.success('Tag saved successfully');
      }
    } catch (e) {
      console.log(e.message);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      <GridRow num_of_columns={2}>
        <GridColumn>
          <DetailItem title="code">
            <SimpleInput
              value={code}
              onInput={setCode}
              disabled={tag_data.id}
              error={errors.code}
            />
          </DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem title="display text">
            <SimpleInput value={value} onInput={setValue} error={errors.value} />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <DetailItem title="description">
            <SimpleInput value={description} onInput={setDescription} />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={3}>
        <GridColumn span={2} />
        <GridColumn>
          <Button text="Save" onClick={submit} disabled={is_saving || !form_is_valid} />
        </GridColumn>
      </GridRow>
    </>
  );
};
