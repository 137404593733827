import { GridColumn, GridRow } from 'app/layouts/grid';
import { Button } from 'app/shared/button';
import { MultiSelect } from 'app/shared/select';
import { useContactService } from 'hooks/users/contact';
import { useTagService } from 'hooks/users/tag';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

export const ContactListTagManager = ({ contacts = [], closeModal = () => {} }) => {
  const { updateContact, updateContacts } = useContactService();
  const { fetchTags } = useTagService();

  const [saving, setSaving] = useState(false);
  const [selected_tags, setSelectedTags] = useState([]);
  const [tag_options, setTagOptions] = useState([]);

  useEffect(() => {
    fetchTags().then(({ tags }) => {
      if (!tags) return;
      const tag_map = tags.reduce(
        (sac, tag) => ({ ...sac, [tag.code]: { ...tag, value: tag.code, label: tag.value } }),
        {}
      );
      setTagOptions(Object.values(tag_map));

      if (contacts.length === 1) {
        const { tags: existing_tags } = contacts[0];
        const pre_selected_tags = existing_tags
          .map((e_tag) => tag_map[e_tag])
          .filter((tag) => !!tag);
        setSelectedTags(pre_selected_tags);
      }
    });
  }, [contacts]);

  const submit = async () => {
    if (contacts.length < 1) return;
    try {
      setSaving(true);
      const tags = selected_tags.map((tag) => tag.code);

      const data = { data: { tags } };
      if (contacts.length > 1) {
        data.options = { id: contacts.map((contact) => contact.id) };
      }

      const result =
        contacts.length > 1
          ? await updateContacts({ data })
          : await updateContact(contacts[0].id, data);
      if (result) {
        toast.success('Tags assigned successfully.');
        closeModal();
      }
    } finally {
      setSaving(false);
    }
  };

  return (
    <>
      <GridRow num_of_columns={1}>
        <GridColumn>
          <MultiSelect
            options={tag_options}
            value={selected_tags}
            isMulti
            onChange={setSelectedTags}
          />
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={3}>
        <GridColumn span={2} />
        <GridColumn>
          <Button text="Save" onClick={submit} loading={saving} />
        </GridColumn>
      </GridRow>
    </>
  );
};
