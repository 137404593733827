import { BasicInfoSection } from 'app/shared/info-section/basic';
import { DetailItem } from 'app/shared/info-section/item';
import { SimpleInput } from 'app/shared/input';
import { MultiSelect } from 'app/shared/select';
import { countries } from 'app/data/countries';
import React, { useContext, useEffect, useState } from 'react';
import { Spacer } from 'app/layouts/generic';
import { Button } from 'app/shared/button';
import useValidator from 'hooks/validator';
import { editContactValidationConfig } from 'app/shared/utils/form/validation/contact';
import { useAudienceService } from 'hooks/users/audience';
import { PermissionsContext } from 'contexts/permissions';
import { toast } from 'react-toastify';
import { useTagService } from 'hooks/users/tag';

const gender_options = [
  {
    value: 'male',
    label: 'Male'
  },
  {
    value: 'female',
    label: 'Female'
  },
  {
    value: 'other',
    label: 'Other'
  }
];

export const ContactUpdateForm = ({
  contact_data,
  is_saving = false,
  onCancel = () => {},
  onSave = () => {}
}) => {
  const { fetchAudiences } = useAudienceService();
  const { fetchTags } = useTagService();
  const { errors, validateField, form_is_valid } = useValidator(editContactValidationConfig);
  const { permissions } = useContext(PermissionsContext);

  const [audiences, setAudiences] = useState([]);
  const [tags, setTags] = useState([]);
  const [selected_tags, setSelectedTags] = useState([]);
  const [form_data, setFormData] = useState({});

  useEffect(() => {
    fetchAudiences().then(({ audiences, error }) => {
      if (error) {
        toast.error('Unable to fetch audiences at this time.');
        return;
      }
      if (!audiences || !audiences.length) return;
      setAudiences(() =>
        audiences.map((audience) => ({ label: audience.name, value: audience.id }))
      );
    });

    fetchTags().then(({ tags }) => {
      if (!tags) return;
      const tag_map = tags.reduce(
        (sac, tag) => ({ ...sac, [tag.code]: { ...tag, value: tag.code, label: tag.value } }),
        {}
      );
      setTags(Object.values(tag_map));

      const pre_selected_tags = (contact_data.tags || []).map((e_tag) => tag_map[e_tag]);
      setSelectedTags(pre_selected_tags);
    });
  }, []);

  useEffect(() => {
    if (!contact_data || !Object.keys(contact_data).length) return;
    const keys = Object.keys(contact_data);

    const parsed_countries = countries.map((country) => ({
      label: country.name,
      value: country.code
    }));

    const sub_ids_hash = (contact_data.subscriptions || []).reduce(
      (sac, audience_id) => ({ ...sac, [audience_id]: 1 }),
      {}
    );

    keys.forEach((key) => {
      const value = contact_data[key];
      if (key === 'gender') {
        setFormData((curr_data) => ({
          ...curr_data,
          gender: gender_options.find((option) => option.value === value)
        }));
      } else if (key === 'address') {
        setFormData((curr_data) => ({
          ...curr_data,
          address: {
            ...(contact_data.address || {}),
            country: parsed_countries.find((country) => country.value === value?.country)
          }
        }));
      } else if (key === 'subscriptions') {
        setFormData((curr_data) => ({
          ...curr_data,
          subscriptions: audiences.filter((audience) => sub_ids_hash[audience.value])
        }));
      } else {
        setFormData((curr_data) => ({
          ...curr_data,
          [key]: value
        }));
      }
    });
  }, [audiences, contact_data]);

  useEffect(() => {
    for (const key of Object.keys(form_data)) {
      validateField(key, form_data[key]);
    }
  }, [form_data]);

  const handleInfoChange = (key, value = '') => {
    switch (key) {
      case 'country':
      case 'street':
        setFormData((curr_data) => ({
          ...curr_data,
          address: {
            ...(curr_data.address || {}),
            [key]: value
          }
        }));
        break;
      default:
        setFormData((curr_data) => ({
          ...curr_data,
          [key]: value
        }));
    }
  };

  const handleSave = () => {
    const data_to_save = {
      ...form_data,
      gender: form_data.gender?.value,
      tags: selected_tags.map((tag) => tag.code),
      address: Object.keys(form_data.address || {}).length
        ? {
            ...form_data.address,
            country: form_data.address?.country?.value
          }
        : {},
      subscriptions: form_data.subscriptions?.map((audiences) => audiences.value)
    };

    onSave(data_to_save);
  };

  return (
    <BasicInfoSection title="Basic Details">
      <DetailItem title="First name">
        <SimpleInput
          value={form_data.firstname}
          onInput={(v) => handleInfoChange('firstname', v)}
          error={errors.firstname}
        />
      </DetailItem>
      <DetailItem title="Last name">
        <SimpleInput
          value={form_data.lastname}
          onInput={(v) => handleInfoChange('lastname', v)}
          error={errors.lastname}
        />
      </DetailItem>
      <DetailItem title="Email address">
        <SimpleInput
          value={form_data.email}
          onInput={(v) => handleInfoChange('email', v)}
          error={errors.email}
        />
      </DetailItem>
      <DetailItem title="Phone number">
        <SimpleInput
          value={form_data.phone_number}
          onInput={(v) => handleInfoChange('phone_number', v)}
        />
      </DetailItem>
      <DetailItem title="Audiences">
        <MultiSelect
          options={audiences}
          value={form_data.subscriptions}
          onChange={(v) => handleInfoChange('subscriptions', v)}
          isMulti
          error={errors.subscriptions}
        />
      </DetailItem>
      <DetailItem title="Gender">
        <MultiSelect
          options={gender_options}
          onChange={(v) => handleInfoChange('gender', v)}
          value={form_data.gender}
          error={errors.gender}
        />
      </DetailItem>
      <DetailItem title="Date of birth">
        <SimpleInput
          type="date"
          value={
            form_data.date_of_birth
              ? new Date(form_data.date_of_birth || '').toISOString().split('T')[0]
              : undefined
          }
          max={new Date().toISOString().split('T')[0]}
          onInput={(e) => handleInfoChange('date_of_birth', e)}
          error={errors.date_of_birth}
        />
      </DetailItem>
      <DetailItem title="Tags">
        <MultiSelect options={tags} value={selected_tags} onChange={setSelectedTags} isMulti />
      </DetailItem>
      <DetailItem title="Street address">
        <SimpleInput
          value={form_data.address?.street}
          onInput={(v) => handleInfoChange('street', v)}
        />
      </DetailItem>
      <DetailItem title="Country">
        <MultiSelect
          options={countries.map((country) => ({
            label: country.name,
            value: country.code
          }))}
          onChange={(v) => handleInfoChange('country', v)}
          value={form_data.address?.country}
          isMulti={false}
        />
      </DetailItem>
      <Spacer multiple={4} />
      <Button
        text="Save changes"
        disabled={!form_is_valid || is_saving || !permissions['contact:update']}
        onClick={handleSave}
      />
      <Spacer multiple={4} />
      <Button type="secondary" text="Cancel" onClick={onCancel} />
    </BasicInfoSection>
  );
};
