export const tagValidator = {
  code: {
    required: true,
    pattern: /^\w+$/
  },
  value: {
    required: true,
    pattern: /^\w+$/
  }
};
