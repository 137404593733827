import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as TagService from 'services/users/tag';

export const useTagService = () => {
  const { token } = useSelector((store) => store.user_data);

  const createTag = async (options = {}) => {
    const { error, payload } = await TagService.create({
      ...options,
      token
    });

    if (error) {
      toast.error(error);
      return null;
    }

    return payload;
  };

  const deleteTag = async (id, options = {}) => {
    const { error, payload } = await TagService.deleteById(id, { ...options, token });
    if (error) {
      toast.error('Unable to delete tag at this time.');
    }

    toast.success('Tag deleted successfully.');
    return payload;
  };

  const deleteTags = async (options = {}) => {
    const { error, payload } = await TagService.deleteBulk({ ...options, token });
    if (error) {
      toast.error('Unable to delete tags at this time.');
    }

    toast.success('Tags deleted successfully.');
    return payload;
  };

  const fetchTag = async (id, options = {}) => {
    const { error, payload } = await TagService.readById(id, { ...options, token });
    if (error) {
      return { tag: {} };
    }

    return { tag: payload };
  };

  const fetchTags = async (options = {}) => {
    const { payload } = await TagService.read({ ...options, token });

    const {
      data: tags,
      meta: { size }
    } = payload;
    return { tags, size, error: null };
  };

  const searchTags = async (keys, keyword, options = {}) => {
    const { error, payload } = await TagService.search(keys, keyword, { ...options, token });
    if (error) {
      return { tags: [], size: 0 };
    }

    const {
      data: tags,
      meta: { size }
    } = payload;
    return { tags, size };
  };

  const updateTag = async (id, options = {}) => {
    const { error, payload, status_code } = await TagService.updateById(id, {
      ...options,
      token
    });
    if (error) {
      toast.error(status_code < 500 ? error : 'Unable to update tag at this time.');
      return null;
    }

    return payload;
  };

  const updateTags = async (options = {}) => {
    const { error, payload, status_code } = await TagService.updateBulk({
      ...options,
      token
    });
    if (error) {
      toast.error(status_code < 500 ? error : 'Unable to update tags at this time.');
      return null;
    }

    return payload;
  };

  return {
    createTag,
    deleteTag,
    deleteTags,
    fetchTag,
    fetchTags,
    searchTags,
    updateTag,
    updateTags
  };
};
