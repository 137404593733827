import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { TagListPage } from './list';

export const ContactTagsPage = () => {
  return (
    <>
      <Routes>
        <Route path="/*" element={<TagListPage />} />
      </Routes>
    </>
  );
};
