import { GridColumn, GridRow } from 'app/layouts/grid';
import { truncateText } from 'app/shared/utils/general';
import { useTagService } from 'hooks/users/tag';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { setPageTitle } from 'store/actions/header';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import { Button } from 'app/shared/button';
import GmModal from 'app/shared/modal/modal';
import { TagConfigForm } from '../config';
import ConfirmationDialog from 'app/shared/dialogs/confirmation';

export const TagListPage = () => {
  const dispatch = useDispatch();
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const { deleteTag, fetchTags, searchTags } = useTagService();

  const [is_search_mode, setSearchMode] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show_delete_confirmation, setShowDeleteConfirmation] = useState(false);
  const [show_tag_form, setShowTagForm] = useState(false);
  const [tags, setTags] = useState({});
  const [tags_count, setTagsCount] = useState(0);
  const [tag_to_edit, setTagToEdit] = useState({});

  const config = {
    actions: {
      single: () => [
        { label: 'Edit', value: 'edit' },
        { label: 'Delete', value: 'delete' }
      ]
    },
    css: {},
    fields: [
      {
        title: 'ID',
        key: 'id',
        formatter: (value) => value || 'N/A'
      },
      {
        title: 'Code',
        key: 'code',
        isTitle: true,
        formatter: (value) => value || 'N/A'
      },
      {
        title: 'Text',
        key: 'value',
        isTagline: true,
        formatter: (value) => value || 'N/A'
      },
      {
        title: 'Description',
        key: 'description',
        formatter: (value) => truncateText(value, 35) || 'N/A'
      }
    ],
    is_search_mode,
    items: Object.values(tags).sort((a, b) => b.time_stamp - a.time_stamp),
    search_key: 'code,value,description',
    search_text: ''
  };

  useEffect(() => {
    dispatch(
      setPageTitle([
        { title: 'Settings', path: '.' },
        { title: 'Contact Tags', path: '.' }
      ])
    );
  }, []);

  const handleCreationResult = (payload = null) => {
    if (!payload) return;
    setTags((curr_tags) => ({ ...curr_tags, [payload.id]: payload }));
    setTagToEdit({});
  };

  const handleDataTableAction = (payload) => {
    const { name, type, data } = payload;

    if (type === 'single') {
      switch (name) {
        case 'edit':
          setTagToEdit(data);
          setShowTagForm(true);
          break;
        case 'delete':
          setTagToEdit(data);
          setShowDeleteConfirmation(true);
          break;
      }
    }
  };

  const handleDataRequest = async (page, population = 50) => {
    setLoading(true);
    const { tags, size, error } = await fetchTags({
      query_string: `page=${page}&population=${population}`
    });

    if (error) toast.error('Unable to load tags at this time');

    setTags((curr_action) => ({
      ...curr_action,
      ...tags.reduce((s, a) => ({ ...s, [a.id]: a }), {})
    }));
    setTagsCount(() => size);
    setLoading(false);
  };

  const handleDelete = async (permission) => {
    if (permission) {
      setDeleting(true);
      const result = await deleteTag(tag_to_edit.id);
      if (result) {
        toast.success('Tag deleted successfully.');
        const curr_tags = tags;
        delete curr_tags[tag_to_edit.id];
        setTags(curr_tags);
        setTagToEdit({});
      }
      setDeleting(false);
    }
    setShowDeleteConfirmation(false);
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {
    if (!keys || !keyword) {
      setSearchMode(false);
      await handleDataRequest(page, population);
      return;
    }

    try {
      setLoading(true);
      if (page === 0) setTags({});
      const { tags, size, error } = await searchTags(keys, keyword, {
        query_string: `page=${page}&population=${population}`
      });

      if (error) return toast.error(error);

      if (is_search_mode) {
        setTags((curr_action) => ({
          ...curr_action,
          ...tags.reduce((s, a) => ({ ...s, [a.id]: a }), {})
        }));
      } else {
        setTags(() => ({
          ...tags.reduce((s, a) => ({ ...s, [a.id]: a }), {})
        }));
        setSearchMode(true);
      }
      setTagsCount(() => size);
    } finally {
      setLoading(false);
    }
  };

  const table_actions = [
    <Button key="add_tag" icon_name="add" text="Create Tag" onClick={() => setShowTagForm(true)} />
  ];

  return (
    <>
      <GridRow>
        <GridColumn span={4}>
          {is_mobile_view ? (
            <MobileDatatable
              config={config}
              onListModeChange={setSearchMode}
              onDataRequest={handleDataRequest}
              onSearchRequest={handleSearchRequest}
              action={handleDataTableAction}
              showHeader
            />
          ) : (
            <WebDatatable
              config={{
                ...config,
                total_count: tags_count
              }}
              checkbox
              loading_data={loading}
              onDataRequest={handleDataRequest}
              onSearchRequest={handleSearchRequest}
              action={handleDataTableAction}
              table_actions={table_actions}
            />
          )}
        </GridColumn>
      </GridRow>
      <GmModal
        show_modal={show_tag_form}
        show_title
        title={`${tag_to_edit.id ? 'Update' : 'Create'} tag`}
        onClose={() => setShowTagForm(false)}
      >
        <TagConfigForm tag_data={tag_to_edit} onSave={handleCreationResult} />
      </GmModal>
      <ConfirmationDialog
        title="Delete tag"
        message={`Are you sure you want to delete tag: ${tag_to_edit.value}? This action cannot be reversed.`}
        is_open={show_delete_confirmation}
        is_loading={deleting}
        callback={handleDelete}
      />
    </>
  );
};
